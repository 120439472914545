import { ReactNode } from "react";

import BlankLayoutWithAppBarWrapper from "src/@core/layouts/BlankLayoutWithAppBar";

import App from "./App";

// const ProjectList = React.lazy(() => import('src/components/Projects/ProjectsList'))

function Index(props: any) {
  return (
    <>
      <App />
    </>
  );
}

Index.getLayout = (page: ReactNode) => (
  <BlankLayoutWithAppBarWrapper>{page}</BlankLayoutWithAppBarWrapper>
);
Index.guestGuard = false;
export default Index;

// export async function getServerSideProps(context: any) {
//   console.log('In server side props')
//   let user = null;
//   try {
//     const { Auth } = withSSRContext(context);
//     user = await Auth.currentAuthenticatedUser();
//   } catch (e) {
//     return {
//       redirect: {
//         destination: "/login",
//       },
//     };
//   }

//   const usr = await prisma.user.findUnique({
//     where: {
//       userName: user.username,
//     },
//   });

//   if (!usr?.profileCompleted) {
//     return {
//       redirect: {
//         destination: "/user-profile/complete",
//       },
//     };
//   }

//   const activeSubscriptions = await prisma.subscription.findMany({
//     where: {
//       accountId: usr?.lastUsedAccountId,
//       isActive: true
//     },
//     orderBy: {
//       id: 'desc'
//     }
//   })

//   if (!usr?.isSuperuser) {
//     const demoProject = await prisma.project.findFirst({
//       where: {
//         accountId: usr?.lastUsedAccountId,
//         isDemoProject: true
//       }
//     });

//     if (!demoProject) {
//       await createDemoProject(usr);
//     }

//     // if (activeSubscriptions.length == 0) {
//     //   return {
//     //     redirect: {
//     //       destination: "/subscription",
//     //     },
//     //   };
//     // }
//   }

//   const userProjectRoles = await prisma.userProject.findMany({
//     where: {
//       userId: usr.id,
//       project: {
//         isTemplate: false
//       }
//     },
//     select: {
//       projectId: true,
//     },
//   });

//   const projectIds = userProjectRoles.map((element: any) => element.projectId);

//   const projects = await prisma.project.findMany({
//     where: {
//       id: {
//         in: projectIds,
//       },
//       isTemplate: false
//     },
//     include: {
//       users: {
//         include: {
//           user: true,
//           role: true,
//           project: {
//             include: {
//               account: true,
//             }
//           }
//         },
//       },
//       account: true,
//       scenes: {
//         include: {
//           Character: true,
//         }
//       },
//       _count: true
//     },
//     orderBy: {
//       updatedAt: 'desc'
//     }
//   });

//   return { props: { projects: JSON.parse(JSON.stringify(projects)), isSubscribed: activeSubscriptions.length > 0 || usr?.isSuperuser, subscriptionStatus: activeSubscriptions.length > 0 ? activeSubscriptions[0].status : 'WaitList' } };
// }
