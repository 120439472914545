import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { styled, useTheme } from "@mui/material/styles";
import { Box } from "@mui/system";

import Link from "next/link";

import { useSettings } from "src/@core/hooks/useSettings";
import ModeToggler from "src/@core/layouts/components/shared-components/ModeToggler";

import UserDropdown from "../shared-components/UserDropdown";

const LinkStyled = styled(Link)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  textDecoration: "none",
  marginRight: theme.spacing(8),
}));

const PentoPixLogo = styled("img")({
  width: "70px",
});

const BlankLayoutAppBar = () => {
  // ** Hooks & Vars
  const theme = useTheme();
  const { settings, saveSettings } = useSettings();
  const { skin } = settings;

  return (
    <AppBar
      color="default"
      position="sticky"
      elevation={skin === "bordered" ? 0 : 3}
      sx={{
        backgroundColor: "background.paper",
        ...(skin === "bordered" && {
          borderBottom: `1px solid ${theme.palette.divider}`,
        }),
      }}
    >
      <Toolbar
        sx={{
          justifyContent: "space-between",
          p: (theme) => `${theme.spacing(0, 6)} !important`,
          minHeight: `${
            (theme.mixins.toolbar.minHeight as number) -
            (skin === "bordered" ? 1 : 0)
          }px !important`,
        }}
      >
        <LinkStyled href="/">
          <PentoPixLogo
            src={`/images/pages/pentopixLogo/pentopix_logo_${theme.palette.mode}.png`}
          />
        </LinkStyled>
        <Box sx={{ display: "flex", gap: "2rem" }}>
          <ModeToggler settings={settings} saveSettings={saveSettings} />
          <UserDropdown settings={settings} />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default BlankLayoutAppBar;
